import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Title } from '../../components/Title';
import { Wrapper } from '../../components/Wrapper';
import { CardCourseDisplay } from '../courses/components/CardCourseDisplay';

import { toDayDate } from '../../common/formatDateText';
import { ContainerFull } from '../../components/ContainerFull';
import { capitalizarPalabras, firstCapitalLetter } from '../../common/upperCaseWord';

import {
	BriefcaseIcon,
	CalendarIcon,
	MapPinIcon,
	SquaresPlusIcon,
} from '@heroicons/react/20/solid'
import { optionsAllCourseListOpenByIdUser } from '../../redux/actions/options';
import { SkeletonPost } from './skeletons/SkeletonPost';
import { PostCard } from './components/post/PostCard';
import { Tooltip, Zoom } from '@mui/material';
import { ModalAddPost } from './components/post/ModalAddPost';
import { getUserPosts } from '../../redux/actions/home';

export const HomePage = () => {
	const dispatch = useDispatch();
	const { coursesListByIdUser } = useSelector((state) => state.options);
	const { posts, loading: loadingPost } = useSelector((state) => state.home);
	const { user } = useSelector((state) => state.user)
	const { _id: userId, firstName, lastName, typeUser, location } = user;
	const [openAddPostModal, setOpenAddPostModal] = useState(false);
	const canView = ["director", "administrativo"];

	useEffect(() => {
		dispatch(optionsAllCourseListOpenByIdUser(userId));
		dispatch(getUserPosts(userId));
	}, [userId, dispatch]);

	return (
		<ContainerFull>
			<div className="w-full px-2">
				<div className="lg:flex lg:items-center lg:justify-between">
					<div className="flex-1">
						<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
							{`Bienvenido ${firstCapitalLetter(firstName)} ${firstCapitalLetter(lastName)}`}
						</h2>
						<div className="mt-1 hidden lg:flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{firstCapitalLetter(typeUser)}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<MapPinIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{capitalizarPalabras(location)}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-500">
								<CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
								{capitalizarPalabras(toDayDate())}
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				canView.includes(typeUser) && (
					<div className='w-full flex justify-end gap-4'>
						{
							canView.includes(typeUser) && (
								<Tooltip title="Agregar un nuevo post" TransitionComponent={Zoom} placement='top'>
									<button
										type='button'
										onClick={() => setOpenAddPostModal(true)}
										className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
									><SquaresPlusIcon className="block h-5 w-5" aria-hidden="true" /></button>
								</Tooltip>
							)
						}
					</div>
				)
			}
			<div className='flex w-[100%] gap-4'>
				{
					(typeUser === 'estudiante' || typeUser === 'profesor') && (
						<div className='hidden lg:flex lg:w-[45%]'>
							<div className="w-full mx-auto">
								{
									coursesListByIdUser.length
										? coursesListByIdUser.map(({
											id,
											headerImage,
											language,
											path, color,
											level,
											status,
											teacher,
											fromDate,
											toDate,
											days,
											hours
										}) => (
											<CardCourseDisplay
												key={id}
												id={id}
												headerImage={headerImage}
												language={language}
												path={path}
												color={color}
												level={level}
												status={status}
												teacher={teacher}
												fromDate={fromDate}
												toDate={toDate}
												days={days}
												hours={hours}
												action={true}
											/>
										))
										: (
											<p className='text-gray-400'>No hay historial de cursos.</p>
										)
								}
							</div>
						</div>
					)
				}
				<div className={`${typeUser !== "estudiante" || typeUser !== "" ? "w-[100%]" : "w-[55%]"} flex flex-col`}>
					{
						loadingPost ? (
							<SkeletonPost />
						) : (
							<div className='w-full h-[100px]'>
								{
									!loadingPost && posts?.length > 0 ?
										posts.map(({ _id, title, createdBy, releaseDate, expirationDate, urlName, message, link }) => (
											<PostCard
												key={_id}
												id={_id}
												userId={userId}
												title={title}
												author={`${createdBy?.firstName} ${createdBy?.secondName} ${createdBy?.lastName} ${createdBy?.secondSurname}`}
												releaseDate={releaseDate}
												expirationDate={expirationDate}
												urlImg={urlName}
												message={message}
												url={link}
												typeUser={typeUser}
											/>
										)) : (
											<div className='flex w-full justify-center items-center bg-white h-[50px] p-8 rounded-md mt-4 shadow-md'>
												<p className='text-gray-400'>No hay publicaciones.</p>
											</div>
										)
								}
								<div className='flex w-full h-[50px]'></div>
							</div>

						)
					}
				</div>
			</div>
			<ModalAddPost
				open={openAddPostModal}
				setOpen={setOpenAddPostModal}
			/>
		</ContainerFull>
	)
}
