import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../../components/Title";
import { Heading } from "../../components/Heading";
import { Wrapper } from "../../components/Wrapper";
import { PaymentHistory } from "../payments/PaymentHistory";
import { ContainerFull } from "../../components/ContainerFull";
import { CardCourseDisplay } from "../courses/components/CardCourseDisplay";

import { getUserById } from "../../redux/actions/users";
import { optionsAllCourseListOpenByIdUser } from "../../redux/actions/options";
import { cleanActionSelectedPayment, getAllPaymentByUserId } from "../../redux/actions/payment";
import { DataUserProfile } from "./DataUserProfile";
import { getAllNotesByUserId } from "../../redux/actions/noteUser";
import { NotaUserCard } from "./NotaUserCard";
import { cleanUserNote } from "../../redux/reducers/noteUser";


export const ProfileUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { userId } = params;
    const { userSelected } = useSelector((state) => state.users);
    const { coursesListByIdUser } = useSelector((state) => state.options);
    const { user } = useSelector((state) => state.user);
    const canEditProfileUser = ["director", "administrativo", "control escolar", "cobranza"];

    useEffect(() => {
        dispatch(cleanUserNote())
    }, [userId, dispatch]);

    useEffect(() => {
        dispatch(getUserById(userId))
    }, [userId, dispatch]);

    useEffect(() => {
        dispatch(getAllNotesByUserId(userId))
    }, [userId, dispatch])

    useEffect(() => {
        dispatch(cleanActionSelectedPayment())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllPaymentByUserId(userId))
    }, [userId, dispatch])

    useEffect(() => {
        dispatch(optionsAllCourseListOpenByIdUser(userId));
    }, [userId, dispatch]);

    const { typeUser } = userSelected;
    const { typeUser: typeloggedUser } = user;

    const handleNavigateEditProfile = () => {
        navigate(`/editar-perfil/${userId}`)
    }

    return (
        <ContainerFull>
            <Heading
                title={`Perfil de usuario`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full md:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <DataUserProfile />
                        {
                            canEditProfileUser.includes(typeloggedUser) && (
                                <div className='w-full flex flex-col lg:flex-row justify-end gap-4 mt-4 lg:mt-20'>
                                    <button
                                        onClick={() => handleNavigateEditProfile()}
                                        type='button'
                                        className='disabled:cursor-not-allowed rounded-lg transition py-2.5 font-semibold text-md text-white text-center bg-indigo-600 hover:bg-indigo-700 w-[100%] lg:w-[25%]'
                                    >Editar perfil</button>
                                </div>
                            )
                        }
                    </div>
                    {
                        typeUser === 'estudiante' && (
                            <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                                <Title title={'Historial de pagos del estudiante'} />
                                <div className="w-full h-[900px] bg-indigo-900/5 rounded-md mt-4 overflow-auto p-4">
                                    <PaymentHistory action={false} />
                                </div>
                            </div>
                        )
                    }
                </div>
            </Wrapper>
            <div className="w-full flex gap-4">

                {
                    (typeUser === 'estudiante' || typeUser === 'profesor') && (
                        <div className='w-full md:w-[40%]'>

                            <Wrapper>
                                <div className='w-full flex flex-col lg:flex-row'>
                                    <div className='w-full border-[1px] border-gray-200 rounded-lg p-4'>
                                        <Title title={'Historial de cursos del estudiante'} />
                                        <div className="w-full lg:w-[100%] mx-auto">
                                            {
                                                coursesListByIdUser.length
                                                    ? coursesListByIdUser.map(({ id, headerImage, language, path, color, level, status, teacher, fromDate, toDate, days, hours }) => (
                                                        <CardCourseDisplay
                                                            key={id}
                                                            id={id}
                                                            headerImage={headerImage}
                                                            language={language}
                                                            path={path}
                                                            color={color}
                                                            level={level}
                                                            status={status}
                                                            teacher={teacher}
                                                            fromDate={fromDate}
                                                            toDate={toDate}
                                                            days={days}
                                                            hours={hours}
                                                            action={false}
                                                        />
                                                    ))
                                                    : (
                                                        <p className='text-gray-400'>No hay cursos en curso o proximos a comenzar.</p>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </Wrapper>
                        </div>
                    )
                }
                {
                    (typeUser === 'estudiante') && (
                        <div className='w-full md:w-[60%]'>

                            <Wrapper>
                                <div className='w-full flex flex-col lg:flex-row gap-4'>
                                    <div className='w-full border-[1px] border-gray-200 rounded-lg p-4'>
                                        <Title title={'Notas'} />
                                        <NotaUserCard userId={userId} />
                                    </div>
                                </div>
                            </Wrapper>
                        </div>
                    )
                }

            </div>
        </ContainerFull>
    )
}
