import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { CardCourse } from './components/CardCourse';

import { useDispatch, useSelector } from 'react-redux';
import { cleanActionSelectedCourse, getAllCoursesByFilters } from '../../redux/actions/course';
import { CardCourseUser } from './components/CardCourseUser';
import { cleanActionNoticeMessages } from '../../redux/actions/noticeMessage';
import { AdjustmentsHorizontalIcon, SquaresPlusIcon } from '@heroicons/react/20/solid';
import { Tooltip, Zoom } from '@mui/material';
import { DrawerFiltersCourse } from './DrawerFiltersCourse';

export const CoursesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const savedFilterData = JSON.parse(localStorage.getItem('filtersCourse'));
    const { user } = useSelector((state) => state.user);
    const { _id: userId, roles, typeUser } = user;
    const courses = useSelector((state) => state.course.courses);
    const [isOpenDrawerFilters, setIsOpenDrawerFilters] = useState(false);
    const [totalCourse, setTotalCourse] = useState(0);
    const [formData, setFormData] = useState({
        userId: userId,
        roles: roles,
        fromDate: savedFilterData?.fromDate ? savedFilterData.fromDate : '',
        toDate: savedFilterData?.toDate ? savedFilterData.toDate : '',
        identifier: savedFilterData?.identifier ? savedFilterData.identifier : '',
        teacher: savedFilterData?.teacher ? savedFilterData.teacher : '',
        level: savedFilterData?.level ? savedFilterData.level : '',
        status: savedFilterData?.status ? savedFilterData.status : '',
        language: savedFilterData?.language ? savedFilterData.language : '',
    });

    useEffect(() => {
        dispatch(cleanActionSelectedCourse())
        dispatch(cleanActionNoticeMessages())
    }, [dispatch]);

    useEffect(() => {
        courses.length > 0 ? setTotalCourse(courses.length) : setTotalCourse(0);
    }, [courses]);

    useEffect(() => {
        if (savedFilterData) {
            dispatch(getAllCoursesByFilters({
                userId: userId,
                roles: roles,
                fromDate: formData?.fromDate ? formData.fromDate : '',
                toDate: formData?.toDate ? formData.toDate : '',
                identifier: formData?.identifier ? formData.identifier.name : '',
                teacher: formData?.teacher ? formData.teacher._id : '',
                level: formData?.level ? formData.level.value : '',
                status: formData?.status ? formData.status.description : '',
                language: formData?.language ? formData.language.value : '',
            }));
        } else {
            dispatch(getAllCoursesByFilters({
                userId: userId,
                roles: roles,
                fromDate: '',
                toDate: '',
                identifier: '',
                teacher: '',
                level: '',
                status: '',
                language: '',
            }));
        }
    }, []);

    const handleCreateNewCourse = () => {
        navigate('/nuevo-curso');
    }

    const toggleDrawerFilters = () => {
        setIsOpenDrawerFilters((prevState) => !prevState);
    }

    const handleApllyFilters = (filters) => {
        const { fromDate, toDate, identifier, teacher, level, status, language } = filters;
        localStorage.setItem('filtersCourse', JSON.stringify({
            fromDate: formData.fromDate ? formData.fromDate : '',
            toDate: formData.toDate ? formData.toDate : '',
            identifier: formData.identifier ? formData.identifier : '',
            teacher: formData.teacher ? formData.teacher : '',
            level: formData.level ? formData.level : '',
            status: formData.status ? formData.status : '',
            language: formData.language ? formData.language : '',
        }));
        dispatch(getAllCoursesByFilters({
            userId,
            roles,
            fromDate,
            toDate,
            identifier,
            teacher,
            level,
            status,
            language,
        }));
        toggleDrawerFilters();
    }

    const handleRemoveFilter = () => {
        setFormData({
            userId: userId,
            roles: roles,
            fromDate: '',
            toDate: '',
            identifier: '',
            teacher: '',
            level: '',
            status: '',
            language: '',
        });
        localStorage.setItem('filtersCourse', JSON.stringify({
            fromDate: '',
            toDate: '',
            identifier: '',
            teacher: '',
            level: '',
            status: '',
            language: '',
        }));
        dispatch(getAllCoursesByFilters({
            userId,
            roles,
            fromDate: '',
            toDate: '',
            identifier: '',
            teacher: '',
            level: '',
            status: '',
            language: '',
        }));
        toggleDrawerFilters();
    }

    return (
        <ContainerFull>
            <Heading
                title={roles === 'admin' ? `Total de cursos encontrados: ${totalCourse}` : 'Mis cursos'}
                center={false}
            />
            {
                <div className='w-full flex justify-end gap-4 mt-4'>
                    {
                        typeUser === 'director' && (
                            <Tooltip title="Panel de filtros" TransitionComponent={Zoom} placement='top'>
                                <button
                                    type='button'
                                    onClick={() => toggleDrawerFilters()}
                                    className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                                ><AdjustmentsHorizontalIcon className="block h-5 w-5" aria-hidden="true" /></button>
                            </Tooltip>
                        )
                    }
                    {
                        typeUser === 'director' && (
                            <Tooltip title="Agregar un nuevo curso" TransitionComponent={Zoom} placement='top'>
                                <button
                                    type='button'
                                    onClick={handleCreateNewCourse}
                                    className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                                ><SquaresPlusIcon className="block h-5 w-5" aria-hidden="true" /></button>
                            </Tooltip>
                        )
                    }
                </div>
            }

            <Wrapper>
                <div className="w-full">

                    {
                        (roles === 'admin' || (roles === 'user' && typeUser === 'profesor')) ? (
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lx:grid-cols-4 gap-6'>
                                {courses.length ?
                                    (courses && courses.map(course => (
                                        <CardCourse
                                            key={course._id}
                                            isCreating={false}
                                            idCourse={course?._id}
                                            identifier={course?.identifier}
                                            color={course?.color}
                                            language={course?.language}
                                            path={course?.path}
                                            nivel={course?.level}
                                            studentLimit={course.limitMembers}
                                            status={course?.status}
                                            hours={course?.hours}
                                            days={course?.days}
                                            teacher={course?.teacher}
                                            fromDate={course?.fromDate}
                                            toDate={course?.toDate}
                                            headerImage={course?.headerImage?.urlName}
                                            updatedBy={course?.updatedBy}
                                            updatedAt={course?.updatedAt}
                                            totalStudents={course?.totalStudents}
                                            expiredPaymentDeadline={course?.expiredPaymentDeadline}
                                            validPaymentDeadline={course?.validPaymentDeadline}
                                        />
                                    ))) : <h1>No hay ningun curso</h1>
                                }
                            </div>
                        ) : (
                            <div className='grid grid-cols-1 lg:grid-cols-1 gap-6'>
                                {courses.length ?
                                    (courses && courses.map(course => (
                                        <CardCourseUser
                                            key={course._id}
                                            idCourse={course?._id}
                                            color={course?.color}
                                            language={course?.language}
                                            path={course?.path}
                                            nivel={course?.level}
                                            status={course?.status}
                                            hours={course?.hours}
                                            days={course?.days}
                                            teacher={course?.teacher}
                                            fromDate={course?.fromDate}
                                            toDate={course?.toDate}
                                        />
                                    ))) : <h1>No hay ningun curso</h1>
                                }
                            </div>
                        )
                    }

                </div>
            </Wrapper>
            <DrawerFiltersCourse
                isOpenDrawerFilters={isOpenDrawerFilters}
                toggleDrawerFilters={toggleDrawerFilters}
                formData={formData}
                setFormData={setFormData}
                handleApllyFilters={handleApllyFilters}
                handleRemoveFilter={handleRemoveFilter}
            />
        </ContainerFull>
    )
}
