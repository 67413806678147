import { useSelector } from 'react-redux';
import { Loading } from '../../components/loading/Loading';
import { Title } from '../../components/Title';
import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { capitalizarPalabras, firstCapitalLetter } from '../../common/upperCaseWord';
import { formatDate } from '../../common/formatDateText';

export const DataUserProfile = () => {
    const { userSelected, loading } = useSelector((state) => state.users);
    const {
        firstName, secondName, lastName,
        secondSurname, email, status,
        phone, dateBirth, location, zipCode,
        education, paymentDeadlineDate, subscriptionPayment,
        matricula, typeUser, emailTax, rfc, businessName, taxRegime
    } = userSelected;

    const { user } = useSelector((state) => state.user);

    const { typeUser: typeUserLoggin } = user;

    const canViewPaymentAndstatus = ["director", "administrativo", "control escolar", "cobranza"];

    return (
        <>
            {
                loading ? (
                    <Loading />
                ) : (
                    <>
                        {
                            userSelected ? (
                                <>
                                    <Title title={'Datos personales'} />
                                    <PropertyListItem>
                                        <PropertyItem
                                            title={`Matricula`}
                                            description={matricula}
                                        />
                                        <PropertyItem
                                            title={`Nombre completo`}
                                            description={`${firstCapitalLetter(firstName)} ${secondName ? firstCapitalLetter(secondName) : ''} ${firstCapitalLetter(lastName)} ${secondName ? firstCapitalLetter(secondSurname) : ''}`}
                                        />
                                        <PropertyItem
                                            title={`Correo electrónico`}
                                            description={email ? email : ''}
                                        />
                                        <PropertyItem
                                            title={`Telefono`}
                                            description={phone ? phone : ''}
                                        />
                                        <PropertyItem
                                            title={`Fecha de nacieminto`}
                                            description={capitalizarPalabras(formatDate(dateBirth))}
                                        />
                                        {
                                            typeUser !== 'estudiante' && (
                                                <PropertyItem
                                                    title={`Tipo de usuario`}
                                                    description={firstCapitalLetter(typeUser)}
                                                />
                                            )
                                        }
                                        {
                                            typeUser === 'estudiante' && (
                                                <PropertyItem
                                                    title={`Nivel de educacion`}
                                                    description={education ? capitalizarPalabras(education) : ''}
                                                />
                                            )
                                        }
                                        {
                                            typeUser === 'estudiante' && (
                                                <PropertyItem
                                                    title={`Monto de la suscripcion`}
                                                    description={subscriptionPayment ? `$ ${subscriptionPayment}` : ''}
                                                />
                                            )
                                        }
                                        <PropertyItem
                                            title={`Lugar de residencia`}
                                            description={capitalizarPalabras(location)}
                                        />
                                        <PropertyItem
                                            title={`Codigo postal`}
                                            description={zipCode}
                                        />
                                        {
                                            (canViewPaymentAndstatus.includes(typeUserLoggin) && typeUser === 'estudiante') && (
                                                <PropertyItem
                                                    title={`Proxima fecha de pago`}
                                                    description={capitalizarPalabras(formatDate(paymentDeadlineDate))}
                                                />
                                            )
                                        }
                                        {
                                            (canViewPaymentAndstatus.includes(typeUserLoggin) && typeUser === 'estudiante') && (
                                                <PropertyItem
                                                    title={`Estatus del estudiante`}
                                                    description={capitalizarPalabras((status))}
                                                />
                                            )
                                        }
                                    </PropertyListItem>

                                    <div className="mt-10">
                                        <Title title={'Datos fiscales'} />
                                        <PropertyListItem>
                                            <PropertyItem
                                                title={`Correo electrónico fiscal`}
                                                description={emailTax}
                                            />
                                            <PropertyItem
                                                title={`RFC`}
                                                description={rfc ? rfc.toUpperCase() : ''}
                                            />
                                            <PropertyItem
                                                title={`Razon social`}
                                                description={businessName ? businessName.toUpperCase() : ''}
                                            />
                                            <PropertyItem
                                                title={`Regimen fiscal`}
                                                description={taxRegime ? taxRegime.toUpperCase() : ''}
                                            />
                                        </PropertyListItem>
                                    </div>
                                </>

                            ) : (
                                <>Cargando datos del usuario.</>
                            )
                        }
                    </>

                )
            }
        </>
    )
}
